.forWhomBlock {
    display: flex;
    align-items: center;
    margin: 16px 0;
    &_img {
        display: inline-block;
        text-align: center;
        margin-bottom: 8px;
        img {
            display: inline-block;
            width: 80px;
            height: 80px;
        }
    }
    &_text {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        color: #4a5978;
        text-align: left;
    }
}

@media screen and (min-width: 576px) {
    .forWhomBlock {
        &_img {
            margin-right: 16px;
            img {
                width: 128px;
                height: 128px;
            }
        }
        &_text {
            font-size: 18px;
        }
    }
}

@media screen and (min-width: 992px) {
    .forWhomBlock {
        &_img {
            img {
                width: 160px;
                height: 160px;
            }
        }
        &_text {
            font-size: 24px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .forWhomBlock {
        &_text {
            max-width: 317px;
        }
    }
}

@media screen and (min-width: 1600px) {
    .forWhomBlock {
        &_img {
            margin-right: 40px;
            img {
                width: 160px;
                height: 160px;
            }
        }
        &_text {
            font-size: 24px;
        }
    }
}
