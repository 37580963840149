@import '../../about.module.scss';

.StatsCard {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 12px 20px 2px #f0f1f4;
    margin: 4px 0;
    text-align: center;
    height: 106px;
    width: 304px;
    .text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
    h2 {
        color: $accent-color;
        font-size: 32px;
        font-weight: 600;
        line-height: 120%;
    }
    p {
        color: $text-color;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        width: 100%;
    }
    .sm {
        display: none;
    }
    .md {
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .StatsCard {
        width: 35%;
        width: 180px;
        p{
            width: 150px;
        }
        .sm {
            display: block;
        }
        .xs {
            display: none;
        }
    }
}

@media screen and(min-width: 768px) {
    .StatsCard {
        width: 212px;
        p{
            padding: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .StatsCard {
        width: 288px;
        height: 154px;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 24px;
        h2{
            font-size: 40px;
            line-height: 50px;
        }
        p{
            width: 219px;
        }
        .md {
            display: block;
        }
        .sm {
            display: none;
        }
    }
}

@media screen and (min-width: 1200px) {
    .StatsCard {
        width: 348px;
        padding: 24px 30px;
        .text {
            margin-top: 8px;
        }
        h2 {
            font-size: 40px;
        }
    }
}

@media screen and (min-width: 1600px) {
    .StatsCard {
        width: 445px;
        padding: 32px 28px;
        p{
            width: 100%;
        }
    }
}
