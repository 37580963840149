@import '../about.module.scss';

.header {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0 28px 0;
    &_title {
        h2 {
            font-size: 28px;
            font-weight: 600;
            color: $text-color;
            margin-bottom: 32px;
            .red {
                color: $accent-color;
            }
        }
    }

    &_image {
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .header {
        &_title {
            h2 {
                font-size: 52px;
                line-height: 120%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .header {
        padding-top: 64px;
        &_title {
            h2 {
                margin-bottom: 40px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .header {
        &_wrapper {
            display: flex;
            align-items: center;
        }
        &_image {
            display: block;
            flex-basis: 100%;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .header {
        &_title {
            h2 {
                font-size: 64px;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .header {
        padding: 22px 0 28px 0;
        &_title {
            margin-top: 50px;
        }
        &_image {
            img {
                height: 508px;
                width: 565px;
            }
        }
    }
}
