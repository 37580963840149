@import '../about.module.scss';

.advantages {
    margin: 40px 0;
    &_content {
        &_title,h2 {
            text-align: center;
            color: #fff;
        }
        &_card {
            display: none;
        }
        &_list {
            margin: 50px 0;
            .breakWord {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .advantages {
        &_content {
            padding: 50px 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .advantages {
        &_content {
            &_title {
                padding: 0 32px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .advantages {
        margin: 80px 0;
        &_content {
            padding: 74px 0;
            &_list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .advantages {
        margin-bottom: 140px;
        &_content {
            &_header {
                display: grid;
                grid-template-columns: 5fr 4fr;
                grid-column-gap: 30px;
                h2{
                    text-align: left!important;
                }
            }
            &_card {
                display: flex;
            }
            &_list {
                grid-template-columns: 1fr 1fr 1fr;
                margin-top: 15px;
                // & > div:nth-child(1) {
                //     display: none;
                // }
                .breakWord {
                    display: block;
                }
            }
        }
    }
}
