@import '../../colors.scss';

.tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    .tab {
        cursor: pointer;
        color: #808a9f;
        margin: 24px 0 0 0;
        &:not(:last-child):nth-child(odd) {
            margin-right: 27px;
        }
        &:hover {
            color: $text-color;
        }
        &__active {
            color: $text-color;
        }
        .tabIcon {
            margin-right: 8px;
        }
    }
}

@media screen and (min-width: 576px) {
    .tabs {
        font-size: 18px;
        line-height: 28px;
        .tab:not(:last-child):nth-child(odd)  {
            margin-right: unset;
        }
        .tab:not(:last-child) {
            margin-right: 27px!important;
        }
    }
}

@media screen and (min-width: 768px) {
    .tabs {
        margin: 32px 0 0 0;
        .tab {
            margin-top: 0px;
        }
        .tab:not(:last-child) {
            margin-right: 37px!important;
        }
    }
}

@media screen and (min-width: 992px) {
    .tabs {
        font-size: 24px;
        line-height: 35px;
        margin: 40px 0 0 0;
        .tab:not(:last-child) {
            margin-right: 60px!important;
        }
    }
}

@media screen and (min-width: 1200px) {
    .tabs {
        font-size: 24px;
        line-height: 35px;
        margin: 48px 0 0 0;
        .tab {
            margin-bottom: 0;
            &:not(:last-child) {
                margin-right: 56px!important;
            }
            .tabIcon {
                margin-right: 20px;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .tabs {
        margin-top: 50px;
    }
}
