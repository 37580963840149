@import '../about.module.scss';

.blockSeo {
    margin-top: 80px;
    @media screen and (min-width: 992px) {
        margin-top: 120px;
    }
    @media screen and (min-width: 1600px) {
        margin-top: 140px;
    }
}
