@import '../../colors.scss';

.container {
    position: relative;
    overflow: hidden;
    &_layout {
        margin: 25px -50px;
        transform: rotate(4deg);
        &__grey {
            background: $bg-grey-color;
        }
        &__red {
            background: $accent-color;
        }
    }
    &_children {
        transform: rotate(-4deg);
        padding: 20px 0;
    }
}

@media screen and (min-width: 576px) {
    .container {
        &_layout {
            margin: 30px -50px;
        }
    }
}

@media screen and (min-width: 768px) {
    .container {
        &_layout {
            margin: 35px -50px;
        }
    }
}

@media screen and (min-width: 992px) {
    .container {
        &_layout {
            transform: rotate(2deg) skewX(2deg);
            margin: 20px 0;
        }
        &_children {
            transform: rotate(-2deg) skewX(-2deg);
        }
    }
}

@media screen and (min-width: 1200px) {
    .container {
        &_layout {
            transform: rotate(2deg) skewX(2deg);
            border-radius: 40px;
            margin-left: auto;
            margin-right: auto;
            max-width: 1160px;
        }
        &_children {
            transform: rotate(-2deg) skewX(-2deg);
        }
    }
}

@media screen and (min-width: 1600px) {
    .container {
        padding: 25px 80px;
        &_layout {
            margin: auto;
            max-width: 1440px;
        }
    }
}
