@import '../about.module.scss';

.network {
    margin-top: 80px;
    &_arrow {
        display: none;
    }
    &_arrow__invert {
        &::before {
            transform: scaleX(-100%);
        }
    }
}
@media screen and (min-width: 576px) {
    .network {
        margin-top: 46px;
        margin-bottom: 50px;
        &_arrow {
            display: block;
            height: 23px;
            &::before {
                content: '';
                display: block;
                margin-left: 43%;
                height: 30px;
                width: 32px;
                background-image: url('./img/arrow1_576.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
}

@media screen and(min-width: 768px) {
    .network {
        margin-top: 100px;
        &_arrow {
            height: 51px;
            &::before {
                content: '';
                margin-left: 50%;
                height: 59px;
                width: 72px;
                background-image: url('./img/arrow1_768.svg');
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .network {
        margin-top: 140px;
        &_arrow {
            position: relative;
            height: 80px;
            margin: -10px 0;
            &::before {
                position: absolute;
                content: '';
                margin-left: 50%;
                height: 90px;
                width: 108px;
                background-image: url('./img/arrow1_992.svg');
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .network {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 160px;
        &_arrow {
            height: 358px;
            display: flex;
            min-height: 100%;
            &::before {
                content: '';
                position: absolute;
                margin-right: 0;
                margin-left: 0;
                height: 100px;
                width: 89px;
                background-image: url('./img/arrow1_1200.svg');
                left: 50%;
                top: 40%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .network {
        margin: 140px 0;
        &_arrow {
            &::before {
                height: 100px;
                width: 85px;
            }
        }
    }
}
