@import '../../colors.scss';
.title {
    color: $text-color;
    font-size: 28px;
    font-weight: 500;
    line-height: 150%;
}

@media screen and (min-width: 576px) {
    .title {
        font-size: 40px;
        line-height: 120%;
    }
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 52px;
        line-height: 62px;
    }
}

@media screen and (min-width: 992px) {
    .title {
        font-size: 64px;
        line-height: 77px;
    }
}

@media screen and (min-width: 1200px) {
    .title {
        font-size: 64px;
    }
}

@media screen and (min-width: 1600px) {
    .title {
        &__bigger{
            font-size: 70px;
            line-height: 91px;
        }
    }
}
