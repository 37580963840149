@import '../about.module.scss';

.BlockOurPartners {
    @media (min-width: 320px) {
        padding: 40px 0 0 0;
        &_title{
            text-align: center;
        }
        &_list{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 16px;
            &>div {
                &:nth-child(n+5){
                    display: none;
                }
        }
        }
    }
    @media (min-width: 576px) {
        padding: 48px 0;
        &_list{
            grid-template-columns: 1fr 1fr;
            &>div {
                &:nth-child(n+5){
                    display: block;
                }
            }
        }
    }
    @media (min-width: 768px) {
        padding: 64px 0;
        &_list{
            grid-gap: 32px;
            &>div {
                &:nth-child(n+5){
                    display: block;
                }
            }
        }
    }
    @media (min-width: 992px) {
        padding: 80px 0;
        &_list {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media (min-width: 1200px) {
        padding-bottom: 0;
    }

    @media (min-width: 1600px) {
        padding: 140px 0 70px 0;
    }
    &__actions {
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        @media (min-width: 320px) {
            max-width: 100%;
        }
        @media screen and (min-width: 1600px) {
            margin-top: 64px;
        }
    }
}
