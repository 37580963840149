@import '../../colors.scss';

.wrapper {
    position: relative;
    padding: 52px 42px;
    margin-top: 53px;
    &_img {
        position: absolute;
        text-align: center;
        top: -52px;
        left: 50%;
        transform: translateX(-50%);
        img {
            display: inline-block;
        }
    }
    &_text {
        h3 {
            font-weight: 500;
            font-size: 18px;
            color: $text-color;
            text-align: center;
        }
        p {
            margin-top: 8px;
            font-size: 14px;
            color: #4d5b79;
            text-align: center;
        }
    }
    &_layout {
        position: absolute;
        background: #edf2f9;
        border-radius: 16px;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transform: rotate(2deg);
    }
}

@media screen and (min-width: 576px) {
    .wrapper {
        padding: 32px 22px;
    }
}

@media screen and (min-width: 768px) {
    .wrapper {
        margin: 60px 0 50px 0;
        &_img {
            top: -90px;
            img {
                height: 120px;
                width: 120px;
            }
        }
        &_text {
            h3 {
                font-size: 24px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrapper {
        &_img {
            z-index: 2;
        }
        &_text {
            position: relative;
            display: block;
            z-index: 1;
        }
        &_layout {
            z-index: 0;
        }
    }
}

@media screen and (min-width: 1600px) {
    .wrapper {
        margin-top: 82px;
    }
}
