@import '../about.module.scss';

.howStartWork {
    margin: 40px 0;
    &_header {
        width: 100%;
        margin-bottom: 100px;
        .howStartWork_buttonWrapper {
            margin: 24px auto 0 auto;
        }
    }
    &_content {
        padding: 35px 0 20px 0;
    }
}

@media screen and (min-width: 576px) {
    .howStartWork {
        margin: 48px 0;
        &_header {
            .howStartWork_buttonWrapper {
                width: 245px;
            }
        }
        &_content {
            &_list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .howStartWork {
        margin: 64px 0;
        &_content {
            padding: 80px 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .howStartWork {
        margin: 80px 0;
        &_content {
            padding: 50px 0 100px 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    .howStartWork {
        &_content {
            display: flex;
            &_list {
                grid-column-gap: 32px;
            }
        }
        &_header {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 51px;
            order: 2;
            width: 340px;
            .howStartWork_title {
                text-align: left !important;
                margin-bottom: 60px;
            }
            .howStartWork_buttonWrapper {
                margin-left: 0;
                margin-right: auto;
                margin-top: 0;
            }
        }
        &_list {
            order: 1;
        }
    }
}

@media screen and (min-width: 1600px) {
    .howStartWork {
        margin: 140px 0;
        &_header {
            width: 35%;
            margin-bottom: unset;
            .howStartWork_title {
                width: 300px;
                line-height: 130%;
            }
        }
        &_content {
            &_list {
                width: 60%;
            }
        }
    }
}
