@import './colors.scss';

.landingContainer {
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 320px) {
        width: 304px;
    }
    @media (min-width: 576px) {
        width: 560px;
    }
    @media (min-width: 768px) {
        width: 672px;
    }
    @media (min-width: 992px) {
        width: 928px;
    }
    @media (min-width: 1200px) {
        width: 1108px;
    }
    @media (min-width: 1600px) {
        width: 1264px;
    }
}
