.RedButton {
    background: transparent;
    border-radius: 4px;
    border: 2px solid #fe0000;
    font-weight: 400;
    color: #fe0000;
    height: 58px;
    line-height: 58px !important;
    width: unset!important;
    min-width: none;
    margin: 0 auto;
    padding: 0 32px;
}
