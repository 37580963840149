@import '../about.module.scss';

.forWhom {
    margin: 80px 0 20px 0;
    &_header {
        &_card {
            display: none;
        }
    }
}

@media screen and (min-width: 576px) {
    .forWhom {
        &_content {
            padding: 20px 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .forWhom {
        &_content {
            padding: 50px 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .forWhom {
        &_header {
            &_title {
                margin-bottom: 50px;
            }
        }
        &_list {
            padding: 0 70px;
        }
    }
}

@media screen and(min-width: 1200px) {
    .forWhom {
        margin-top: 115px;
        &_header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 120px;
            &_title {
                text-align: left !important;
                padding-right: 40px;
            }
            &_card {
                display: flex;
            }
        }
        &_list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 120px;
            padding: 0;
            & > div:nth-child(1) {
                display: none;
            }
        }
    }
}

@media screen and(min-width: 1600px) {
    .forWhom {
        &_header {
            grid-column-gap: 25px;
            &_title {
                padding-left: 20px;
                padding-right: 90px;
            }
        }
        &_content {
            padding: 74px 0;
        }
        &_list {
            grid-column-gap: 25px;
        }
    }
}
