.advantageBlock {
    background: linear-gradient(130.87deg, rgba(158, 28, 28, 0.5) 0%, rgba(158, 28, 28, 0) 99.53%);
    border-radius: 16px;
    padding: 16px;
    margin: 16px 0;
    &_img {
        display: none;
    }
    &_text {
        color: #fff;
        &_title {
            margin-bottom: 8px;
            img {
                display: none;
            }
        }
        h4 {
            font-weight: 600;
            font-size: 18px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
        }
    }
}

@media screen and (min-width: 576px) {
    .advantageBlock {
        position: relative;
        padding-left: 56px;
        margin-left: 38px;
        min-height: 152px;
        &_img {
            position: absolute;
            left: -30px;
            display: block;
            width: 80px;
            height: 80px;
        }
    }
}

@media screen and (min-width: 768px) {
    .advantageBlock {
        padding-top: 32px;
        padding-bottom: 24px;
        padding-right: 24px;
        &_img {
            height: 102px;
            width: 102px;
            left: -55px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        &_text {
            &_title {
                margin-bottom: 16px;
            }
            h4 {
                font-size: 24px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .advantageBlock {
        padding: 24px;
        display: flex;
        &:nth-child(4){
            .advantageBlock_text_title {
                width: 200px;
            }
        }
        &_img {
            display: none;
        }
        &_img__XL {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            position: absolute;
            left: -90px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            width: 187px;
            img {
                height: auto;
                width: 187px;
                max-width: unset !important;
            }
        }
        &_img__XL + &_text {
            padding-left: 90px;
        }
        &_img__XL + &_text &_text_title {
            padding-left: 0;
        }
        &_text {
            padding-right: 25px;
        }
        &_text_title {
            display: flex;
            margin-bottom: 16px;
            padding-left: 50px;
            &__short{
                width: 160px!important;
            }
            img {
                display: block;
                position: absolute;
                left: -35px;
                top: 10px;
                width: 102px;
                height: 102px;
            }
        }
    }
}
