@import '../../about.module.scss';

.btn {
    background-color: $accent-color;
    padding: 10px 32px;
    border-radius: 4px;
    width: 100%;
    min-height: 58px;
}

@media screen and(min-width: 576px) {
    .btn{
        width: auto;
        min-width: 245px;
    }
}
