@import '../about.module.scss';
.instruments {
    margin: 60px 0 20px 0;
    &_wrapper {
        display: flex;
    }
    .list {
        margin: 16px 0 0 0;
        :global(.ant-collapse-expand-icon) {
            display: none;
        }
        :global(.ant-collapse-item-active .ant-collapse-header-text) {
            color: $accent-color;
        }
        :global(.ant-collapse-header) {
            padding: 0 0 0 16px!important;
        }
        :global(.ant-collapse-header-text) {
            font-weight: 500;
            font-size: 18px;
            color: $text-color;
            padding-top: 16px;
        }
        :global(.ant-collapse-content-box) {
            position: relative;
            color: #4b5a79;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                margin-left: -12px;
                width: 4px;
                border-radius: 2px;
                background-color: #e6e8ec;
            }
        }
        :global(.ant-collapse-borderless
                > .ant-collapse-item
                > .ant-collapse-content
                > .ant-collapse-content-box) {
            font-weight: 400;
            font-size: 14px;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 8px;
        }
        :global(.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header) {
            padding: 12px 40px 0 16px;
        }
    }
    .image {
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .instruments {
        margin: 70px 0 24px 0;
        &_wrapper {
            margin-top: 16px;
        }
        .list {
            margin: 0;
            :global(.ant-collapse-header-text) {
                padding-top: 24px;
            }
            :global(.ant-collapse-content-box) {
                position: relative;
                font-size: 16px !important;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin-left: -16px;
                    width: 4px;
                    border-radius: 2px;
                    background-color: #e6e8ec;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .instruments {
        margin: 70px 0 40px 0
    }
}

@media screen and (min-width: 992px) {
    .instruments {
        margin: 50px 0 56px 0;
        &_wrapper {
            margin-top: 32px;
        }
        .list {
            width: 40%;
            :global(.ant-collapse-header-text) {
                padding-top: 32px;
            }
            :global(.ant-collapse-header) {
                padding: 0 0 0 16px!important;
            }
            :global(.ant-collapse-header-text) {
                font-size: 24px;
                line-height: 35px;
            }
            :global(.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header) {
                padding: 6px 40px 0 16px;
            }
        }
        .image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 722px;
        }
    }
}


@media screen and (min-width: 1200px) {
    .instruments {
        &_wrapper {
            margin-top: 32px;
        }
    }
}

@media screen and (min-width: 1600px) {
    .instruments {
        margin: 50px 0 140px 0;
        &_wrapper {
            margin-top: 48px;
        }
        .list {
            margin-top: 0;
            :global(.ant-collapse-header-text) {
                font-size: 24px;
            }
        }
    }
}
